import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

export default class Banner {
  constructor(rootNode) {
    this.root = rootNode;

    this.container = this.root.querySelector('.content-container');
    this.text = this.container.querySelector('.banner-text');

    this.initBannerText();
  }

  initBannerText() {
    const textWidth = this.text.getBoundingClientRect().width;
    const neededAmount = innerWidth / textWidth;

    this.text.style.setProperty('animation-duration', `${textWidth / 25}s`);

    for (let i = 0; i < neededAmount; i++) {
      const textCopy = this.text.cloneNode(true);
      this.container.appendChild(textCopy);
    }
  }
}
