import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

import Litepicker from 'litepicker';

export default class Booking {
  constructor(rootNode, props) {
    this.root = rootNode;

    // this.initLocationPicker();
    this.initNumberButtons();
    this.initMoreGamersButton();
    this.initDatePicker();
    this.initDateRestriction();
    this.initAmountOfGamers();
    // this.initAmountOfCompanions();

    this.priceGamer = props.priceGamer;
    this.priceCompanion = props.priceCompanion;

    this.amountGamers = 0;
    this.amountDays = 0;
    this.amountCompanions = 0;
  }

  allowSubmit(allow){
    const submit = this.root.querySelector('#booking_book');
    submit.disabled = allow;
  }

  initLocationPicker() {
    const locationPicker = this.root.querySelector('#booking_location');

    locationPicker.value = '';
  }

  initDateRestriction() {
    const datePicker = this.root.querySelector('#date-picker');
    // const locationPicker = this.root.querySelector('#booking_location');

    // const ranges = JSON.parse(datePicker.getAttribute('data-ranges'));
    
    // locationPicker.addEventListener('input', () => {
    //   const locationId = locationPicker.value;

    //   this.allowedRange = ranges[`${locationId}`];

    //   this.resetDate();

    //   this.litepicker.setOptions({
    //     minDate: this.allowedRange.start,
    //     maxDate: this.allowedRange.end,
    //   });
    // });
    
    const range = JSON.parse(datePicker.getAttribute('data-range'));
    
    this.allowedRange = range;
    
    this.litepicker.setOptions({
      minDate: this.allowedRange.start,
      maxDate: this.allowedRange.end,
    });
  }

  resetDate() {
    this.litepicker.clearSelection();

    const elements = this.root.querySelectorAll("[data-bind='date']");
    elements.forEach((element) => {
      element.innerHTML = element.getAttribute('data-placeholder');
    });

    this.isFirstPick = true;
  }

  initMoreGamersButton() {
    const moreGamersContainer = this.root.querySelector('.more-gamers');
    const moreGamersButton = this.root.querySelector('.more-gamers-button');

    moreGamersButton.addEventListener('click', () => {
      moreGamersContainer.classList.remove('hidden');
      moreGamersContainer.classList.add('contents');
      moreGamersButton.classList.add('hidden');
    });
  }

  initNumberButtons() {
    const numberButtons = this.root.querySelectorAll('.number-toggle');

    numberButtons.forEach((button) => {
      const input = button.parentNode.querySelector('input');

      button.addEventListener('click', () => {
        const currentValue = input.value;

        let newValue = currentValue;

        if (button.classList.contains('toggle-plus')) {
          ++newValue;
        }

        if (button.classList.contains('toggle-minus')) {
          --newValue;
        }

        if (newValue >= input.min && newValue <= input.max) {
          input.value = newValue;
        }

        input.dispatchEvent(new Event('input', { bubbles: true }));
      });
    });
  }

  initDatePicker() {
    this.litepicker = new Litepicker({
      element: this.root.querySelector('#date-picker'),
      lang: 'de-DE',
      position: 'right',
      singleMode: false,
      showTooltip: false,
    });

    // Scroll to first possible date (set it active and unactive to achieve that)
    this.litepicker.on('before:show', () => {
      this.litepicker.setStartDate(this.allowedRange.start);
    });
    this.litepicker.on('show', () => {
      if (this.isFirstPick) this.litepicker.clearSelection();
    });
    this.litepicker.on('selected', () => {
      this.isFirstPick = false;
    });

    this.litepicker.on('selected', (dateFrom, dateTo) => {
      this.amountDays = this.getDaysBetween(
        dateTo.dateInstance,
        dateFrom.dateInstance
      );

      this.updatePrice();
      this.allowSubmit(this.amountDays === 0);

      const elements = this.root.querySelectorAll("[data-bind='date']");

      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const formattedDates = [
        dateFrom.dateInstance.toLocaleDateString('de-DE', options),
        dateTo.dateInstance.toLocaleDateString('de-DE', options),
      ];

      elements.forEach((element) => {
        element.innerHTML = `${formattedDates[0]} - ${formattedDates[1]}`;
      });

      const inputFrom = this.root.querySelector(
        "input[name='booking[dateFrom]']"
      );
      inputFrom.value = this.convertToUSFormat(dateFrom.dateInstance);

      const inputTo = this.root.querySelector("input[name='booking[dateTo]']");
      inputTo.value = this.convertToUSFormat(dateTo.dateInstance);
    });
  }

  initAmountOfGamers() {
    const amountContainer = this.root.querySelector('.amount-of-gamers');
    const amountInputs = amountContainer.querySelectorAll('input');

    amountInputs.forEach((input) => {
      input.addEventListener('input', () => {
        this.amountGamers = input.value;

        this.updatePrice();
        this.updateGamerAmountSummary();
        this.updateTeamNameContainer();
      });
    });
  }

  initAmountOfCompanions() {
    const input = this.root.querySelector('#booking_companions');
    input.addEventListener('input', () => {
      this.amountCompanions = input.value;
      this.updatePrice();
    });
  }

  updateTeamNameContainer() {
    const teamNameContainer = this.root.querySelector('.team-name');

    teamNameContainer.classList.toggle('hidden', this.amountGamers < 2);
  }

  updateGamerAmountSummary() {
    const amountOutput = this.root.querySelector(
      "[data-bind='amountOfGamers']"
    );
    amountOutput.innerHTML = `${this.amountGamers} Spieler`;
  }

  updatePrice() {
    const priceOutput = this.root.querySelector("[data-bind='price']");
    const price =
      (this.amountGamers * this.priceGamer +
        this.amountCompanions * this.priceCompanion) *
      this.amountDays;

    priceOutput.innerHTML = price;
  }

  convertToUSFormat(date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  }

  getDaysBetween(date1, date2) {
    const days = Math.floor((Date.parse(date1) - Date.parse(date2)) / 86400000);
    return days;
  }
}
