import gsap from 'gsap';

const MIN_SIZE = 50;
const MAX_SIZE = 200;

const MIN_AMOUNT = 3;
const MAX_AMOUNT = 4;

const MIN_ANIMATION_TIME = 8;
const MAX_ANIMATION_TIME = 16;

export default class CardCircle {
  constructor(rootNode) {
    this.root = rootNode;

    this.initCircles();
  }

  initCircles() {
    const amount = this.getRandomNumberBetween(MIN_AMOUNT, MAX_AMOUNT);

    for (let i = 0; i < amount; i++) {
      const size = this.getRandomNumberBetween(MIN_SIZE, MAX_SIZE);

      const bounding = this.root.getBoundingClientRect();
      const maxLeft = bounding.width - size;
      const maxTop = bounding.height - size;

      const left = this.getRandomNumberBetween(0, maxLeft);
      const top = this.getRandomNumberBetween(0, maxTop);

      const circle = document.createElement('div');
      circle.classList.add('circle');
      circle.style.left = `${left}px`;
      circle.style.top = `${top}px`;
      circle.style.height = `${size}px`;
      circle.style.width = `${size}px`;

      const duration = this.getRandomNumberBetween(
        MIN_ANIMATION_TIME,
        MAX_ANIMATION_TIME
      );

      gsap.to(circle, {
        left: `random(0, ${maxLeft})`,
        top: `random(0, ${maxTop})`,
        duration,
        ease: 'none',
        repeat: -1,
        repeatRefresh: true,
      });

      this.root.appendChild(circle);
    }
  }

  getRandomNumberBetween(min, max) {
    return Math.round(Math.random() * (max - min) + min);
  }
}
