import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

import Litepicker from 'litepicker';

export default class Form {
  constructor(rootNode) {
    this.root = rootNode;

    this.numberButtons = this.root.querySelectorAll('.number-toggle');

    this.language = navigator.language || navigator.userLanguage;

    this.initForm();
    this.initDatePicker();
    this.initAmountOfGamers();
    this.initAmountOfCompanions();

    this.amountGamers = 0;
    this.amountCompanions = 0;
  }

  initMoreGamersButton() {
    const moreGamersContainer = this.root.querySelector('.more-gamers');
    const moreGamersButton = this.root.querySelector('.more-gamers-button');

    moreGamersButton.addEventListener('click', () => {
      moreGamersContainer.classList.remove('hidden');
      moreGamersContainer.classList.add('contents');
      moreGamersButton.classList.add('hidden');
    });
  }

  initNumberButtons() {
    const numberButtons = this.root.querySelectorAll('.number-toggle');

    numberButtons.forEach((button) => {
      const input = button.parentNode.querySelector('input');

      button.addEventListener('click', () => {
        const currentValue = input.value;

        let newValue = currentValue;

        if (button.classList.contains('toggle-plus')) {
          ++newValue;
        }

        if (button.classList.contains('toggle-minus')) {
          --newValue;
        }

        if (newValue >= input.min && newValue <= input.max) {
          input.value = newValue;
        }

        input.dispatchEvent(new Event('input', { bubbles: true }));
      });
    });
  }

  initDatePicker() {
    const picker = new Litepicker({
      element: this.root.querySelector('#date-picker'),
      lang: this.language,
      position: 'right',
      singleMode: false,
      showTooltip: false,
    });

    picker.on('selected', (dateFrom, dateTo) => {
      const elements = this.root.querySelectorAll("[data-bind='date']");

      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      const formattedDates = [
        dateFrom.dateInstance.toLocaleDateString(this.language, options),
        dateTo.dateInstance.toLocaleDateString(this.language, options),
      ];

      elements.forEach((element) => {
        element.innerHTML = `${formattedDates[0]} - ${formattedDates[1]}`;
      });

      const inputFrom = this.root.querySelector(
        "input[name='booking[dateFrom]']"
      );
      inputFrom.value = this.convertToUSFormat(dateFrom.dateInstance);

      const inputTo = this.root.querySelector("input[name='booking[dateTo]']");
      inputTo.value = this.convertToUSFormat(dateTo.dateInstance);
    });
  }

  initAmountOfGamers() {
    const amountContainer = this.root.querySelector('.amount-of-gamers');
    const amountInputs = amountContainer.querySelectorAll('input');

    amountInputs.forEach((input) => {
      input.addEventListener('input', () => {
        this.amountGamers = input.value;

        this.updatePrice();
        this.updateGamerAmountSummary();
        this.updateTeamNameContainer();
      });
    });
  }

  initAmountOfCompanions() {
    const input = this.root.querySelector('#booking_companions');
    input.addEventListener('input', () => {
      console.log(123);
      this.amountCompanions = input.value;
      this.updatePrice();
    });
  }

  updateTeamNameContainer() {
    const teamNameContainer = this.root.querySelector('.team-name');

    teamNameContainer.classList.toggle('hidden', this.amountGamers <= 2);
  }

  updateGamerAmountSummary() {
    const amountOutput = this.root.querySelector(
      "[data-bind='amountOfGamers']"
    );
    amountOutput.innerHTML = `${this.amountGamers} Spieler`;
  }

  updatePrice() {
    const priceOutput = this.root.querySelector("[data-bind='price']");

    priceOutput.innerHTML =
      this.amountGamers * 250 + this.amountCompanions * 200;
  }

  convertToUSFormat(date) {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split('T')[0];
  }
}
