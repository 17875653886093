import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class PriceTeaser {
  constructor(rootNode) {
    this.root = rootNode;

    this.circles = this.root.querySelectorAll('.circles > *');

    this.initScrollAnimation();
  }

  initScrollAnimation() {
    const animation = gsap.timeline();
    animation.to(this.circles, { opacity: 1, stagger: 0.1 });

    ScrollTrigger.create({
      animation,
      trigger: this.root,
      start: '20% center',
      end: 'bottom-=20% center',
      scrub: 1,
    });
  }
}
