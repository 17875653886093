import { Swiper, Navigation } from 'swiper';
Swiper.use([Navigation]);

export default class ImageSlider {
  constructor(rootNode) {
    this.root = rootNode;

    this.initSlider();
  }

  initSlider() {
    const swiper = new Swiper(this.root, {
      spaceBetween: 10,
      slidesPerView: 1.3,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  }
}
