const INTERVAL_SPEED = 100;
const THRESHOLD = 10;
const SCROLL_PADDING = 50;

export default class Title {
  constructor(rootNode) {
    this.root = rootNode;

    this.initBorderAnimation();

    this.links = Array.from(this.root.querySelectorAll('.nav-link'));
    this.active = 0;
    this.initActiveElementListener();
  }

  initBorderAnimation() {
    setInterval(() => {
      let top = window.getComputedStyle(this.root).getPropertyValue('top');
      top = top.substring(0, top.length - 2); // removing 'px'
      top = Number(top);

      const boundingTop = this.root.getBoundingClientRect().top;

      if (boundingTop < top + THRESHOLD) {
        this.root.style.setProperty('--border-width', '100vw');
      } else {
        this.root.style.setProperty('--border-width', '100%');
      }
    }, INTERVAL_SPEED);
  }

  initActiveElementListener() {
    if (!this.links.length) return;

    this.links[0].classList.add('active');

    const hrefs = this.links.map((link) => link.getAttribute('href'));
    const targets = hrefs.map((href) => {
      if (href == '#') return;
      const target = document.querySelector(href);
      if (target) return target;
    });

    setInterval(() => {
      for (let i = targets.length - 1; i >= 0; i--) {
        if (!targets[i]) return;
        if (targets[i].getBoundingClientRect().top < SCROLL_PADDING) {
          return this.setActive(i);
        }
      }

      // if user is at the very top
      this.setActive(0);
    }, INTERVAL_SPEED);
  }

  setActive(i) {
    if (this.active == i) return;
    this.links[this.active].classList.remove('active');
    this.active = i;
    this.links[this.active].classList.add('active');
  }
}
