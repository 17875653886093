import gsap from 'gsap';

export default class LocationAccordion {
  constructor(rootNode) {
    this.root = rootNode;

    this.activeItem = null;
    this.items = this.root.querySelectorAll('.accordion-item');

    this.initAccordion();
  }

  initAccordion() {
    this.items.forEach((item) => {
      const button = item.querySelector('.toggle-accordion');

      button.addEventListener('click', () => {
        item.classList.toggle('open');

        const body = item.querySelector('.accordion-body');

        const animation = gsap.timeline({
          paused: true,
          defaults: { duration: 0.5 },
        });

        animation.fromTo(
          body,
          { maxHeight: 0 },
          { maxHeight: body.scrollHeight }
        );

        animation.fromTo(body, { opacity: 0, x: -20 }, { opacity: 1, x: 0 });

        if (item.classList.contains('open')) {
          animation.play();
        } else {
          animation.reverse(1);
        }
      });
    });
  }
}
